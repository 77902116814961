import React from 'react';
import PropTypes from 'prop-types';
import { Loader, ProgressiveBg, SrLabel } from '@luigiclaudio/ga-baseline-ui/helpers';
import { A } from '@luigiclaudio/ga-baseline-ui/typography';
import CurrentProductImageContainer from './CurrentProductImageContainer';

const CurrentProductImage = ({ image, url, title, imageHeight, imageWidth }) => {
    if (!image) {
        return <Loader isFullScreen />;
    }

    return (
        <CurrentProductImageContainer $height={imageHeight} $width={imageWidth}>
            <A path={url}>
                <SrLabel as="span">{title}</SrLabel>
                <ProgressiveBg image={image} />
            </A>
        </CurrentProductImageContainer>
    );
};

CurrentProductImage.propTypes = {
    image: PropTypes.string,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageHeight: PropTypes.number.isRequired,
    imageWidth: PropTypes.number.isRequired,
};

CurrentProductImage.defaultProps = {
    image: undefined,
};

export default CurrentProductImage;
