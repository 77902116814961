import styled from 'styled-components';
import { rem } from 'polished';

const CurrentProductImageContainer = styled.div`
    border-radius: ${({ theme }) => rem(theme.base.borderRadius.md)};
    height: ${({ $height }) => rem($height)};
    margin: 0 auto;
    max-width: ${({ $width }) => rem($width)};
    overflow: hidden;
    width: 100%;
`;

export default CurrentProductImageContainer;
